import React from "react";
import styled from "styled-components";

const Heading1 = styled.h1`
  font-size: 3rem;
  font-weight: bold;
`;

const Heading2 = styled.h2`
  font-size: 2rem;
  font-weight: normal;
`;

const Home = () => {
  return (
    <div>
      <Heading1>Hello, I’m Charlotte</Heading1>
      <Heading2>a data scientist currently studying at 42 Paris</Heading2>
    </div>
  );
};

export default Home;