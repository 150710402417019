import './App.css';
import React, { useState } from 'react';
import Home from "./Home/Home";
import ProjectsPage from "./Project/ProjectPage";
import ContactForm from './ContactForm/ContactForm';

function App() {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          <Home />
          <button 
            className="contact-button" 
            onClick={toggleModal}>
            Get in Touch
          </button>
        </p>
      </header>
      <main>
      </main>
      <footer className="App-footer">
        <p>© 2024 charlotte madrangeas · 
          <a href="https://github.com/chmadran" style={{ color: 'black', textDecoration: 'none' }}> github</a> · 
          <a href="https://www.linkedin.com/in/charlotte-madrangeas-25a021155/" style={{ color: 'black', textDecoration: 'none' }}> linkedin</a>
        </p>
      </footer>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button 
              className="close-button" 
              onClick={toggleModal}>
              &times;
            </button>
            <ContactForm />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
